// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Utilities
import { localize } from 'utilities';
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import PageIntroduction from 'components/pageIntroduction';
import Button from 'components/button';
import RelatedStories from 'components/_stories/relatedStories';

const WholesalePageComponent = ({ pageContext, content, location }) => {
    const { labels, locale } = pageContext;
    const { seoMetaTags, introduction, contactEmail, relatedPosts } = content;

    // Set theme
    const theme = useTheme(s.color('white'));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <PageIntroduction {...{ content: introduction }} />
                    <p>
                        <a
                            href="https://wholesale.coffeecollective.dk/"
                            target="_blank">
                            <Button
                                {...{
                                    theme,
                                    type: 'secondary',
                                    label: labels.PAGES.WHOLESALE.CONTACT,
                                }}
                            />
                        </a>
                    </p>
                    {relatedPosts.length > 0 && (
                        <RelatedStories
                            {...{
                                label: labels.PAGES.WHOLESALE.RELATED_POSTS,
                                pageContext,
                                location,
                                stories: relatedPosts,
                            }}
                        />
                    )}
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    ${s.responsive.property('padding-top', {
        0: 100,
        30: 140,
    })};

    > a {
        display: block;

        ${s.responsive.property('margin-top', {
            0: '-3em',
            40: '-5em',
        })};

        ${s.responsive.property('margin-bottom', {
            0: '3em',
            40: '5em',
        })};
    }
`;
const NamedWholesalePageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageWholesale } = useStaticQuery(graphql`
        query {
            allDatoCmsPageWholesale {
                edges {
                    node {
                        name
                        locale
                        introduction
                        contactEmail
                        seoMetaTags {
                            ...SEO
                        }
                        relatedPosts {
                            ...BlogPostShort
                        }
                    }
                }
            }
        }
    `);

    return (
        <WholesalePageComponent
            {...{
                content: localize(allDatoCmsPageWholesale, locale),
                ...props,
            }}
        />
    );
};

export default NamedWholesalePageComponent;
